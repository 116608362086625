import { IconArrowUpRight } from '@tabler/icons-react'
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react'
import './styles/works.css'

import image from '../../assets/img/team/TimePhoto_20230911_100025.jpg'
import { useDB } from '../../context/DBContext';
import { URL } from '../../config/config';

export default function Works ({ page }) {

    const location = useLocation();
    const { works } = useDB();

    const [ worksPosition, setWorkPosition ] = useState(0)


    useEffect(() => {

        if (works.length > 0) {
        
            let amount = page === 'summary' ? 2 : works.length - 1
            const interval = setInterval(() => {
                setWorkPosition((prevPosition) => {
                    if (prevPosition === amount) {
                        return 0; // Regresa a 0 si llega a 2
                    }
                    return prevPosition + 1; // Incrementa en 1
                });
            }, 2000); // Cambia el número a la cantidad de milisegundos que deseas para el intervalo

            return () => clearInterval(interval);
        
        }

    }, [page, works.length])

    return (

        <>
        
            <div className="__lines_separare"></div>

            <section className='__section __section_works'>

                <div className='__content_section'>

                    <div className='__row __row_A'>

                        <div className='__col __col_a'>
                            <h2>
                                <p className='__xtx_1'>Mejoramos,</p>
                                <p className='__xtx_2'>cuidamos y</p>
                                <p className='__xtx_3'>aseguramos</p>
                            </h2>
                        </div>

                        <div className='__col __col_p'>
                            <h3>{works[worksPosition]?.title_project}</h3>
                            <p>{works[worksPosition]?.summary_project}</p>
                        </div>

                    </div>

                    <div className={`__row __row_B ${!page ? '__row_B_grd' : ''}`}>

                        {page === 'summary' ? (
                            <>
                                {works.slice(0, 3).map((w, index) => (
                                    <div key={w.id_project} className={`__card_work ${worksPosition === index ? '__card_work--active' : ''}`} style={{backgroundImage: `url(${w.images[0]})`}}>
                                        <img src={image} alt='Hombres trabajando...' />
                                        <Link to={`${URL}/projects/${w.slug_project}`} className='__a_card_work'>
                                            <div className='__go'><IconArrowUpRight/></div>
                                            <div className='__description'>
                                                <h3>{w.title_project}</h3>
                                                <div className='__line'></div>
                                                <p>{w.summary_project}</p>
                                            </div>

                                        </Link>
                                    </div>
                                ))}
                            </>
                        ) : (
                            works.map((w, index) => (
                                <div key={w.id_project} className={`__card_work ${worksPosition === index ? '__card_work--active' : ''}`} style={{backgroundImage: `url(${w.images[0]})`}}>
                                    <img src={image} alt='Hombres trabajando...' />
                                    <Link to={`${URL}/projects/${w.slug_project}`} className='__a_card_work'>
                                        <div className='__go'><IconArrowUpRight/></div>
                                        <div className='__description'>
                                            <h3>{w.title_project}</h3>
                                            <div className='__line'></div>
                                            <p>{w.summary_project}</p>
                                        </div>

                                    </Link>
                                </div>
                            ))
                        )}

                    </div>

                    {location.pathname === '/' && (
                        <div className='__row __row_C'>
                            <Link to={'/projects'} className='__a_link_more'>Ver más proyectos</Link>
                        </div>
                    )}

                </div>

            </section>

        </>

    )

}