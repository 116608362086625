import { createContext, useContext, useEffect, useState } from "react";

const DBContext = createContext();

export const DBProvider = ({ children }) => {

    const [ redes, setRedes ] = useState([]);
    const [ works, setWorks ] = useState([]);
    const [ blogs, setBlogs ] = useState([]);

    const handleListRedes = async () => {

        try {
            
            const response = await fetch('https://api.northenergyeirl.com/dashboard/network/')

            if (!response.ok) {
                throw new Error("Error fetching network dashboard response");
            }

            const data = await response.json();

            if (data.ok) {
                setRedes(data.data)
            } else {
                console.log(data.message);
            }

        } catch (error) {
            console.error(error);
        }

    }

    const handleListWorks = async () => {
        try {
            const response = await fetch(`https://api.northenergyeirl.com/dashboard/?projects`)
            const data = await response.json();
            if (data.ok) {
                setWorks(data.projects)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleListBlogs = async () => {
        try {
            
            const response = await fetch(`https://api.northenergyeirl.com/dashboard/?blogs`);
            const data = await response.json();
            if (data.ok) {
                setBlogs(data.articles);
            }

        } catch (error) {
            
            console.log(error);

        }
    }

    useEffect(() => {
        if (redes.length === 0 || works.length === 0 || blogs.length === 0) {
            handleListRedes();
            handleListWorks();
            handleListBlogs();
        }
    }, [redes.length, works.length, blogs.length]);

    const contextValue = {
        redes,
        works,
        blogs,
        handleListRedes,
        handleListWorks,
        handleListBlogs
    }

    return (
        <DBContext.Provider value={contextValue}>{children}</DBContext.Provider>
    )

}

export const useDB = () => useContext(DBContext);