import company1 from '../assets/img/companies/company1.webp'
import company2 from '../assets/img/companies/company2.webp'
import company3 from '../assets/img/companies/company3.webp'
import company4 from '../assets/img/companies/company4.webp'
import company5 from '../assets/img/companies/company5.webp'
import company6 from '../assets/img/companies/company6.webp'
import company7 from '../assets/img/companies/company7.webp'
import company8 from '../assets/img/companies/company8.webp'
import company9 from '../assets/img/companies/company9.webp'
import company10 from '../assets/img/companies/company10.webp'
import company11 from '../assets/img/companies/company11.webp'
import company12 from '../assets/img/companies/company12.webp'
import company13 from '../assets/img/companies/company13.webp'
import company14 from '../assets/img/companies/company14.webp'
import company15 from '../assets/img/companies/company15.webp'
import company16 from '../assets/img/companies/company16.webp'
import company17 from '../assets/img/companies/company17.webp'
import company18 from '../assets/img/companies/company18.webp'

export const services = [];

export const questions = [
    {
      "pregunta": "¿Qué incluye el desarrollo de estudios definitivos y proyectos técnicos eléctricos?",
      "respuesta": "Incluye análisis de requerimientos energéticos, diseño de planos eléctricos, cálculos de carga, selección de materiales, simulaciones de rendimiento y tramitación de permisos ante autoridades reguladoras."
    },
    {
      "pregunta": "¿Qué tipo de instalaciones eléctricas manejan en los proyectos electromecánicos?",
      "respuesta": "Nos especializamos en instalaciones de baja tensión para naves industriales, incluyendo cableado estructurado, montaje de tableros, sistemas de iluminación y centros de control de motores."
    },
    {
      "pregunta": "¿Con qué frecuencia se debe realizar el mantenimiento de redes eléctricas?",
      "respuesta": "El mantenimiento debe realizarse de manera preventiva cada 6 meses o cuando haya indicios de desgaste o mal funcionamiento para evitar interrupciones y garantizar la seguridad."
    },
    {
      "pregunta": "¿Qué incluye el mantenimiento de subestaciones eléctricas?",
      "respuesta": "Incluye inspección de equipos, pruebas de aislamiento, verificación de sistemas de puesta a tierra, limpieza de componentes y ajustes necesarios para optimizar el rendimiento."
    },
    {
      "pregunta": "¿Qué es una auditoría eléctrica y por qué es importante?",
      "respuesta": "Es una revisión integral de las instalaciones eléctricas para garantizar seguridad, eficiencia y cumplimiento normativo. Es importante para prevenir riesgos y mejorar el rendimiento energético."
    },
    {
      "pregunta": "¿Ofrecen servicios de emergencia o mantenimiento urgente?",
      "respuesta": "Sí, contamos con un equipo preparado para responder a emergencias eléctricas, ofreciendo reparaciones rápidas y eficaces para evitar paradas prolongadas."
    },
    {
      "pregunta": "¿Realizan proyectos en zonas residenciales y comerciales?",
      "respuesta": "Sí, ofrecemos servicios de instalación y mantenimiento eléctrico tanto en zonas residenciales como comerciales, adaptando nuestros proyectos a las necesidades específicas de cada cliente."
    },
    {
      "pregunta": "¿Cómo puedo solicitar una cotización para un proyecto eléctrico?",
      "respuesta": "Puedes contactarnos a través de nuestro formulario en línea, llamarnos o enviarnos un correo. Analizaremos tu proyecto y te brindaremos una cotización personalizada."
    }
];  

export const clientes =  [
  company1,
  company2,
  company3,
  company4,
  company5,
  company6,
  company7,
  company8,
  company9,
]
export const clientes2 =  [
  company10,
  company11,
  company12,
  company13,
  company14,
  company15,
  company16,
  company17,
  company18,
]