import { useParams } from "react-router-dom"
import { useDB } from "../context/DBContext";

import ogDefault from '../assets/img/og-default.png'
import { Helmet } from "react-helmet";
import Works from "../layout/sections/Works";
import Projects from "../layout/sections/Projects";

export default function WorkPage () {

    const { slug } = useParams();
    const { works } = useDB();

    const work = works.find((w) => w.slug_project === slug);

    if (!work) return <h2>Proyecto no encontrado</h2>

    const ogImage = work.images[0] || ogDefault

    return (

        <>
        
            <Helmet>
                <title>{work.title_project} - NORTH ENERGY E.I.R.L. - Soluciones Eléctricas para Residencias, Comercio e Industria</title>
                <meta name="description" content={work.summary_project} />
                <meta name="keywords" content={`blog soluciones eléctricas, mantenimiento eléctrico, instalación eléctrica, electricidad residencial, electricidad comercial, electricidad industrial, North Energy blog, ${work.summary_project}`} />
                <meta name="author" content="ARCANA CORP SAC, https://arcana.codes" />
                <meta name="robots" content="index, follow" />

                {/* Open Graph Meta Tags (para Facebook y LinkedIn) */}
                <meta property="og:title" content={work.title_project} />
                <meta property="og:description" content={work.summary_project} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://northenergyeirl.com/blog/${work.slug_project}`} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:site_name" content="Tu Sitio Web" />
                <meta property="og:locale" content="es_ES" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={work.title_project} />
                <meta name="twitter:description" content={work.summary_project} />
                <meta name="twitter:image" content={ogImage} />
                <meta name="twitter:site" content="@tu_usuario_twitter" />
                <meta name="twitter:creator" content="@tu_usuario_twitter" />

                {/* Meta Tags específicos de LinkedIn (opcional) */}
                <meta property="linkedin:site" content="https://northenergyeirl.com" />
                <meta property="linkedin:creator" content="ARCANA CORP SAC" />

                {/* Canonical URL */}
                <link rel="canonical" href={`https://northenergyeirl.com/blog/${work.slug_project}`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "headline": work.title_project,
                        "datePublished": work.date_project,
                        "author": {
                            "@type": "Organization",
                            "name": "NORTH ENERGY E.I.R.L.",
                            "url": "https://northenergyeirl.com"
                        },
                        "image": ogImage,
                        "url": `https://northenergyeirl.com/blog/${work.slug_project}`,
                        "description": work.summary_project,
                    })}
                </script>
            </Helmet>

            <Projects project={work} />

            <Works page={'summary'}/>

        </>

    )

}