import { Link } from 'react-router-dom'

import logo from '../assets/img/logo-white.svg'

import './styles/footer.css'
import { IconArrowUp, IconArrowUpRight, IconBrandFacebook, IconBrandLinkedin, IconBrandMailgun, IconMail } from '@tabler/icons-react'
import { useState } from 'react'
import moment from 'moment'
import { URL } from '../config/config'
import { useDB } from '../context/DBContext'

export default function Footer () {

    const { redes } = useDB();

    const [ isEmail, setIsEmail ] = useState('');
    const [ isMessage, setIsMessage ] = useState('');
    const [ isOk, setIsOk ] = useState(false);

    const year = new Date().getFullYear()

    const handleSendEmail = async () => {

        if (!isEmail) {
            setIsMessage("Por favor, introduce un correo electrónico.");
            return;
        }
    
        // Validación de formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(isEmail)) {
            setIsMessage("Por favor, introduce un correo electrónico válido.");
            return;
        }

        try {
            
            const currentDay = moment().format('YYYY-MM-DD');

            const formData = new FormData();
            formData.append('email', isEmail)
            formData.append('date', currentDay)

            const response = await fetch(`https://api.northenergyeirl.com/news/`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error('Error en el envío. Intenta nuevamente.')
            }

            const data = await response.json();

            if (data.ok) {
                setIsOk(true);
                setIsMessage(data.message)
            } else {
                setIsMessage(data.message)
            }

        } catch (error) {
            setIsMessage(error.message)
        }

    }

    return (

        <footer className="__footer">

            <div className='__content_footer'>

                <div className='__col __col_A'>

                    <div className='__log'>

                        <Link to={`${URL}`} title="Somos NORTH ENERGY E.I.R.L una empresa dedicada a brindar servicios electromecánicos" aria-label="Ir a la sección de inicio">
                            <img src={logo} alt='Logo de NORTH ENERGY EIRL - EMPRESA DE SERVICIOS ELECTROMECÁNICOS EN PERÚ' aria-label='Logo de NORTH ENERGY EIRL - EMPRESA DE SERVICIOS ELECTROMECÁNICOS EN PERÚ' title='Logo de NORTH ENERGY EIRL - EMPRESA DE SERVICIOS ELECTROMECÁNICOS EN PERÚ' />
                        </Link>

                    </div>
                    
                    <div className='__form'>
                        <div className='__form_group'>
                            
                            {!isOk ? (
                                <>
                                    <label htmlFor='email'>Déjanos tu email para recibir más información</label>
                                    <div className='__form_control'>
                                        <div className='__bnt __bnt_ico'><IconBrandMailgun/></div>
                                        <input type='email' id='email' placeholder='Déjanos tu email para recibir más información' aria-placeholder='Déjanos tu email para recibir más información' onChange={(e) => setIsEmail(e.target.value)} />
                                        <button className='__bnt __bnt_send' onClick={handleSendEmail}><IconArrowUpRight/></button>
                                    </div>
                                </>
                            ) : (
                                <h4 style={{color: '#FFFFFF'}}>{isMessage}</h4>
                            )}
                        </div>
                    </div>

                    <div className='__copy_'>
                        <p>© {year} <Link to={`${URL}`} title="Somos NORTH ENERGY E.I.R.L una empresa dedicada a brindar servicios electromecánicos" aria-label="Ir a la sección de inicio">NORTH ENERGY EIRL</Link>, All rights reserved.</p>
                        <p>RUC: <b>20605713212</b></p>
                        <p style={{color: '#888888', marginTop: '.2rem'}}>Desarrollado y diseñado por <Link to={'https://arcana.codes'} title='Desarrollado y diseñado por ARCANA CORP para NORTH ENERGY EIRL' aria-label='Desarrollado y diseñado por ARCANA CORP para NORTH ENERGY EIRL' style={{color: '#B3B3B3'}} target='_blank'>ARCANA CORP</Link></p>
                    </div>

                </div>

                <div className='__col __col_B'>

                    <Link to={'/'} className='__a_back'><IconArrowUp/></Link>

                    <nav>
                        <ul>
                            <li><span>Empresa</span></li>
                            <div className='__sep'></div>
                            <li>
                                <Link to={`${URL}`} title="Somos NORTH ENERGY E.I.R.L una empresa dedicada a brindar servicios electromecánicos" aria-label="Ir a la sección de inicio">Inicio</Link>
                            </li>
                            <li>
                                <Link to={`${URL}/services`} title="Encuetra todos nuestros servicios y como trabajamos en NORTH ENERGY E.I.R.L" aria-label="Ir a la sección de nuestros servicios donde ofrecemos las soluciones que necesitas">Servicios</Link>
                            </li>
                            <li>
                                <Link to={`${URL}/projects`} title="Encuetra todos nuestros proyectos y como resolvemos problemas en NORTH ENERGY E.I.R.L" aria-label="Ir a la sección de nuestros proyectos donde trabajamos activamente">Proyectos</Link>
                            </li>
                        </ul>
                        <ul>
                            <li><span>Recursos</span></li>
                            <div className='__sep'></div>
                            <li>
                                <Link to={`${URL}/blog`} title="Encuetra todos nuestros articulos de mayor interes de nuestros expertos en NORTH ENERGY E.I.R.L" aria-label="Ir a la sección de nuestro blog con artículos y noticias">Blog</Link>
                            </li>
                            <li>
                                <Link to={'https://www.bumeran.com.pe/perfiles/empresa_north-energy-e.i.r.l._13209667.html'} target='_blank' rel="noopener noreferrer" title="Encuetra todos nuestros puestos vacantes y únete al equipo de NORTH ENERGY E.I.R.L" aria-label="Ir a nuestra bolsa de trabajo en Bumeran y se parte de nosotros">Bolsa de Trabajo</Link>
                            </li>
                        </ul>
                        <ul>
                            <li><span>Nosotros</span></li>
                            <div className='__sep'></div>
                            <li>
                                <Link to={`${URL}/about`} title="Encuetra más sobre nosotros la visión, misión y cultura empresarial que manejamos en NORTH ENERGY E.I.R.L" aria-label="Ir a la sección de nosotros donde te contamos más sobre nosotros">Nosotros</Link>
                            </li>
                            <li>
                                <Link to={`${URL}/contact`} title="Encuetra todos nuestros medios de contacto y como ubicarnos NORTH ENERGY E.I.R.L" aria-label="Ir a la sección de contacto con todas nuestras redes sociales">Contáctanos</Link>
                            </li>
                        </ul>
                    </nav>

                </div>
                
                <div className='__col __col_C'>

                    <ul>
                        {redes.fb !== '' && redes.fb !== undefined && (
                            <li>
                                <Link to={`${redes.fb}`} target='_blank' rel="noopener noreferrer" title="Síguenos en Facebook como NORTH ENERGY y conocenos más sobre nuestro trabajo en el sector." aria-label='Síguenos en LinkedIn para leer articulos de mayor interes escritos por nuestro equipo de NORTH ENERGY E.I.R.L'><IconBrandFacebook/></Link>
                            </li>
                        )}
                        {redes.in !== '' && redes.in !== undefined && (
                            <li>
                                <Link to={`${redes.in}`} target='_blank' rel="noopener noreferrer" title="Síguenos en LinkedIn para leer articulos de mayor interes escritos por nuestro equipo de NORTH ENERGY E.I.R.L" aria-label='Síguenos en LinkedIn para leer articulos de mayor interes escritos por nuestro equipo de NORTH ENERGY E.I.R.L'><IconBrandLinkedin/></Link>
                            </li>
                        )}
                        {redes.ml !== '' && redes.ml !== undefined && (
                            <li>
                                <Link to={`https://mail.google.com/mail/?view=cm&fs=1&to=${redes.ml}`} target='_blank' rel="noopener noreferrer" title="Escribenos un correo para poder contactarnos contigo y ver como NORTH ENERGY E.I.R.L puede ayudarte" aria-label='Escribenos un correo para poder contactarnos contigo y ver como NORTH ENERGY E.I.R.L puede ayudarte'><IconMail/></Link>
                            </li>
                        )}
                    </ul>

                </div>

            </div>

        </footer>

    )

}