import { Link } from 'react-router-dom'
import './styles/project.css'
import { IconBrandFacebook, IconBrandLinkedin, IconBrandX } from '@tabler/icons-react'
import moment from 'moment'
import Masonry from 'react-masonry-css'

export default function Projects ({ project }) {

    const formatted = moment(project.date_project).format('LL')

    const start = moment(project.start_project).format('L')
    const end = project.finish_project !== null ? moment(project.finish_project).format('L') : 'Actualidad'

    const breakpointColumnsObj = {
        default: 2, // Número de columnas por defecto
        1100: 2,    // Número de columnas en pantallas medianas
        700: 2,     // Número de columnas en pantallas pequeñas
        500: 1      // Número de columnas en pantallas muy pequeñas
    };

    return (

        <>
        
            <section className='__section __section_project'>

                <div className='__content_section'>

                    <div className='__images'>
                        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column" >
                            {project?.images.map((image, index) => (
                                <div key={index} style={{borderRadius: '10px', overflow: 'hidden'}}>
                                    <img src={image} alt={project.summary_project} style={{ width: '100%' }} />
                                </div>
                            ))}
                        </Masonry>
                    </div>

                    <div className='__content_blog'>
                        <div className='__line'></div>
                        <article className='__article' itemscope itemtype="https://schema.org/BlogPosting">
                            <h2 aria-label={project.title_project}>
                                <p className='__p_1'><em itemprop="datePublished" content={project.date_project}><time datetime={project.date_project}>{formatted}</time></em></p>
                                <p className='__p_2' itemprop="headline">{project.title_project}</p>
                            </h2>

                            <div style={{marginBottom: '1rem'}}><p style={{fontWeight: 500}}>Inicio: {start}</p> <p style={{fontWeight: 500}}>{project.finish_project !== null ? 'Fin:' : 'Proceso:'} {end}</p></div>

                            <div dangerouslySetInnerHTML={{__html: project.text_project}} itemprop="articleBody" id='contentBlog'></div>
                            <footer className='__share_on'>
                                <div className='__share'>
                                    <h4>Compartir:</h4>
                                    <ul>
                                        <li>
                                            <Link target='_blank' to={`https://www.linkedin.com/sharing/share-offsite/?url=https://northenergyeirl.com/blog/${project.slug_project}`}><IconBrandLinkedin/></Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' to={`https://www.facebook.com/sharer/sharer.php?u=https://northenergyeirl.com/blog/${project.slug_project}`}><IconBrandFacebook/></Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' to={`https://twitter.com/intent/tweet?url=https://northenergyeirl.com/blog/${project.slug_project}`}><IconBrandX/></Link>
                                        </li>
                                    </ul>
                                </div>
                            </footer>
                        </article>
                    </div>

                </div>

            </section>
        
        </>

    )

}